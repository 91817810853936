.project-select-wrapper {
  padding: 0 10px;
  background: #fff;
  .item {
    min-width: 100%;
    margin: 16px 0;
  }
  .border-radius-8 {
    border-radius: 8px;
  }
}

.margin-right-20 {
  margin-right: 20px;
}
