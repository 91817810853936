.member-wrapper {
  width: 1200px;
  margin: 0 auto;
  .member-create-btn {
    display: flex;
    justify-content: flex-end;
    padding: 18px 0 0 0;
    padding-right: 16px;
  }
  .member-list-wrapper {
    .next-table .next-table-header th .next-table-cell-wrapper {
      display: flex;
      justify-content: flex-start;
    }
    .next-table-header-icon {
      margin-top: 2px;
    }
    .next-table-cell.last .next-table-cell-wrapper {
      text-align: left;
      .next-btn-text {
        padding-left: 0;
      }
    }
  }
  .roles-permPolicies {
    display: inline-block;
    padding: 6px 16px;
    color: #fff;
    background: #47a664;
    border-radius: 14px;
  }
}
