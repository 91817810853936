.workflow-graph {
  --color-workflow-step-bg: #fff;
  --color-workflow-step-connector-bg: #85d4ff;
  --color-border-default: #85d4ff;
  --color-workflow-step-header-shadow: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  transform-origin: left top;
  cursor: grab;
  .step {
    position: relative;
    display: inline-flex;
    background-color: #fff;
    border: var(--color-border-default) 1px solid;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px #60617029;
    &.active {
      --color-border-default: var(--primary-color);
      --color-workflow-step-connector-bg: var(--primary-color);
    }
    &.group {
      border-top-left-radius: 0px;
    }
    .step-title {
      position: absolute;
      top: -21px;
      left: -1px;
      padding: 4px var(--padding-common) 0 var(--padding-common);
      font-weight: 500;
      font-size: var(--font-size-small);
      background-color: var(--color-workflow-step-bg);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      box-shadow: inset 0 1px 0 var(--color-border-default), inset 1px 0 0 var(--color-border-default),
        inset -1px 0 0 var(--color-border-default), 0 -1px 2px var(--color-workflow-step-header-shadow);
      transition: background-color ease-out 0.12s, box-shadow ease-out 0.12s;
      &:after {
        position: absolute;
        top: 5px;
        right: -19px;
        width: 20px;
        height: 16px;
        margin-left: 16px;
        border-bottom-left-radius: 6px;
        box-shadow: inset 1px 0 0 var(--color-border-default), inset 0 -1px 0 var(--color-border-default),
          -1px 3px var(--color-workflow-step-bg);
        transition: box-shadow ease-out 0.12s;
        content: '';
      }
    }
    .groups {
      position: relative;
      padding: var(--padding-common);
      transition: background-color ease-out 0.12s, border-color ease-out 0.12s, box-shadow ease-out 0.12s;
      .mode {
        text-align: center;
      }
    }
    .workflow-step-port {
      position: absolute;
      top: 30px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      &:before {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 14px;
        height: 14px;
        background-color: var(--color-workflow-step-bg);
        border-radius: 50%;
        transition: background-color ease-out 0.12s;
        content: '';
      }
      &::after {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        background-color: var(--color-workflow-step-connector-bg);
        border-radius: 50%;
        transition: background-color ease-out 0.12s;
        content: '';
      }
    }
    .workflow-step-port-output {
      right: -8px;
      background-image: linear-gradient(
        90deg,
        var(--color-workflow-step-bg) 0%,
        var(--color-workflow-step-bg) 50%,
        var(--color-border-default) 50%,
        var(--color-border-default) 100%
      );
    }
    .workflow-step-port-input {
      left: -8px;
      background-image: linear-gradient(
        270deg,
        var(--color-workflow-step-bg) 0%,
        var(--color-workflow-step-bg) 50%,
        var(--color-border-default) 50%,
        var(--color-border-default) 100%
      );
    }
  }
  .workflow-connectors {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform-origin: left top;
  }
  .workflow-connector {
    transition: stroke ease-out 0.12s, stroke-width ease-out 0.12s, opacity ease-out 0.12s;
    stroke: var(--color-workflow-step-connector-bg);
    stroke-width: 2px;
    &:hover {
      stroke: var(--color-workflow-step-connector-bg-hover);
    }
  }

  .step-status {
    display: flex;
    flex: auto;
    align-items: center;
    width: 100%;
    padding: var(--spacing-4);
    white-space: nowrap;
    border-radius: 6px;
    &:hover {
      background: #f6f4ed;
    }
    .step-icon {
      display: inline-block;
      width: 18px;
      height: 16px;
      overflow: visible;
      vertical-align: text-bottom;
      fill: currentColor;
    }
    .step-name {
      flex: 1;
      margin-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .color-fg-success {
      color: var(--success-color);
    }
    .color-fg-danger {
      color: var(--failed-color);
    }
    .icon-rotate {
      animation: rotate-keyframes 1s linear infinite;
    }
    .pending-icon {
      color: var(--running-color);
    }
    .stopped-icon {
      color: var(--failed-color);
    }
    @keyframes rotate-keyframes {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
        animation-duration: 1s;
        animation-play-state: running;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: none;
      }
    }
  }
}
