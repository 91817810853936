.menu-item {
  display: block;
  padding: 8px 0 8px 32px;
}

.menu-item-active {
  display: block;
  padding: 8px 0 8px 32px;
  color: #fff !important;
  background-image: linear-gradient(to right, #0830f1, #1b242f);
}

.menu-item-icon {
  width: 16px;
  height: 16px;
}

.menu-item-text {
  flex: initial;
  height: 16px;
  margin-left: 6px;
  font-size: 14px;
  font-family: SFProText-Medium;
  line-height: 16px;
  letter-spacing: 0;
}
.menu-item:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #0830f1, #1b242f);
}

.menu-item-text-active {
  color: #fff !important;
  background-image: linear-gradient(to right, #0830f1, #1b242f);
}

a:hover {
  text-decoration: none !important;
}

.menu-set {
  &:hover {
    &::before {
      color: #000;
    }
  }
  &::before {
    color: #ccc;
    font-size: 30px !important;
  }
}

.slide-wrapper {
  display: flex;
  flex-direction: column;
  a:link {
    color: rgb(211, 204, 204) !important;
  }
  ul,
  li {
    list-style: none;
    a {
      color: rgb(211, 204, 204);
    }
  }
  .first-nav {
    color: #87878a;
  }
  .nav-container {
    color: #87878a;
    .main-nav {
      height: 24px;
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 24px;
    }
    .sub-wrapper {
      margin-bottom: 20px;
    }
  }
  .ul-wrapper {
    font-size: 16px;
    ul {
      font-size: 16px;
    }
    .remote,
    .next-icon.next-medium::before {
      font-size: 15px;
    }
  }
}

.bottom {
  position: absolute;
  bottom: 10px;
  padding-left: 32px;
  cursor: pointer;
  p {
    color: #fff;
  }
}
