.roles-list-content {
  min-height: calc(100vh - 60px);
  padding: 16px;
  padding-top: 0;
  .next-table .next-table-header th .next-table-cell-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
    .next-btn-text {
      padding-left: 0;
    }
  }
  .roles-permPolicies {
    display: inline-block;
    padding: 6px 16px;
    color: #fff;
    background: #47a664;
    border-radius: 14px;
  }
}
