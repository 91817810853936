.diff-box {
  height: 600px;
  overflow-y: auto;
  background: #000;
  p {
    margin: 0;
  }
}

.application-diff {
  background: #000 !important;
  .next-dialog-body {
    margin: 0 !important;
    padding: 16px !important;
  }
  .name {
    padding: 4px;
    color: #000;
    background: #fff;
    border-radius: 4px;
  }
  .name.base {
    background: #0a0;
  }
  .name.target {
    background: #a00;
  }
}
