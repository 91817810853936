.card-content-wraper {
  padding: 16px;
  padding-bottom: 0;
  border: none;
  .next-card {
    border: none;
    border-radius: 8px;
  }
  .next-card-show-divider .next-card-body {
    padding: 0;
  }
  .content-main-btn {
    min-height: 26px;
  }
  .content-wraper {
    padding: 20px 10px 0 10px;
    background: #f7f6fb;
    .content-main {
      display: -webkit-box;
      height: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .content-foot {
      padding: 16px 0 24px;
    }
  }
}
