.cluster-container {
  display: flex;
  align-items: center;
  .cluster-params-input {
    flex: 1 1 auto;
    margin-right: 8px !important;
  }
  .cluster-option-btn {
    flex: 0 0 80px;
    text-align: center;
  }
}
