.definitions-menu-content {
  .menu-item-wrapper {
    margin-bottom: 10px;
    border: 1px solid #979797;
    border-radius: 5px;
    .menu-item-description {
      display: flex;
      align-items: center;
      height: 64px;
      color: #333;
      font-size: 16px;
      word-break: break-all;
    }
    .menu-item-icon {
      display: flex;
      align-items: center;
      height: 64px;
      color: #333;
      font-size: 16px;
    }
  }
  .menu-item-wrapper:hover {
    background-color: var(--btn-pure-primary-bg-color);
    border: 1px solid hsl(229, 83%, 55%);
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    transition: 0.3s;
    .menu-item-description {
      color: #fff;
    }
    .menu-item-icon {
      color: #fff;
    }
  }
  .active-menu-item {
    color: #fff;
    background-color: var(--btn-pure-primary-bg-color);
    border: 1px solid #1b58f4;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    transition: 0.3s;
    .menu-item-description {
      color: #fff;
    }
    .menu-item-icon {
      color: #fff;
    }
  }
}
