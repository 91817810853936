.graph-container {
  display: flex;
  align-items: center;
  justify-content: start;
  min-width: 100%;
  max-width: 100%;
  min-height: calc(100vh - 443px);
  padding: 32px;
  overflow: auto;
  background: rgba(171, 205, 239, 0.2);
  //position: relative;
  .operation {
    position: absolute;
    top: 32px;
    z-index: 100;
  }
}
.graph-container.top-center {
  justify-content: center;
}
