.app-select-wrapper {
  background: #fff;
  .item {
    min-width: 100%;
    margin: 16px 0;
  }
  .btn-wrapper {
    float: right;
    margin: 20px;
  }
}

.margin-right-20 {
  margin-right: 20px;
}

.show-mode {
  display: flex;
  align-items: center;
  float: right;
  height: 70px;
}
.flexboth {
  display: flex;
  justify-content: space-between;
}
