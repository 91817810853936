.deployConfig {
  .next-radio-group {
    width: 100%;
    .next-radio-wrapper {
      width: 100%;
      margin-bottom: 8px;
      padding: var(--spacing-4);
      border: 1px solid #c0c6cc !important;
      border-radius: 4px;
      cursor: pointer;
    }
    .next-radio-wrapper:hover {
      border: 1px solid var(--primary-color) !important;
    }
  }
  .deploy-workflow-select-item-title {
    display: inline-flex;
    justify-content: space-between;
    width: 90%;
    .deploy-notice {
      color: var(--grey-300);
      display: flex;
      align-items: center;
      margin-left: var(--spacing-4);
    }
  }
  .deploy-workflow-select-item {
    display: flex;
    padding: var(--spacing-4) 0;
    justify-content: space-between;
    flex-flow: row nowrap;
    align-items: center;
    .status {
      display: inline;
    }
    .env {
      flex-grow: 1;
      width: 100px;
      display: flex;
      justify-content: end;
      align-items: center;
    }
    .enable-canary-deploy {
      margin-left: var(--spacing-4);
    }
  }
}
