.cluster-input-wrapper {
  height: 80px;
  background: #fff;
  .item {
    min-width: 300px;
    margin: 20px 15px;
  }
  .btn-wrapper {
    float: right;
    margin: 20px;
  }
}

.margin-right-20 {
  margin-right: 20px;
}
