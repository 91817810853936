.general-wrapper {
  margin-top: 40px;
  .card-wrapper {
    min-height: 200px;
    .card-title-wrapper {
      display: flex;
      justify-content: space-between;
      .card-title {
        margin-top: 6px;
        margin-left: 4px;
        color: #000;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .card-button-wrapper {
      margin-top: 6px;
      margin-right: 4px;
    }
  }
}
