.info-item {
  white-space: nowrap;
  min-width: 80px;
}
.page-header{
  margin-top: 10px;
  .page-header-logo {
    min-width: 50px;
  }
  .page-header-title{
    margin-top: 0;
    font-size: 24px;
  }
  .basic-info{
    width: fit-content;
    font-size: 16px;
  }
  .info-item-title{
    color: var(--grey-500)
  }
  .status-enabled{
    color: var(--success-color)
  }
  .status-disabled{
    color: var(--danger-color)
  }
}
