.ui-schema-container,
.group-container {
  .next-form-item {
    .next-form-item-control {
      width: 100%;
    }
    .next-form-item-label {
      label {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .next-input-group > .next-input.next-large {
    border-left: 1px solid #c0c6cc !important;
  }
}
