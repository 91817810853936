.layout-top-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: var(--primary-color);
  cursor: pointer;
  -webkit-app-region: drag;
  .windows-option-view {
    position: absolute;
    right: 5px;
    z-index: 2;
    display: flex;
    align-items: center;
    -webkit-app-region: no-drag;
    .option-icon {
      width: 18px;
      height: 18px;
      margin-right: 12px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .min {
      width: 16px;
      height: 16px;
      // background-image: url('../assets/min.png');
    }
    .max {
      // background-image: url('../assets/max.png');
    }
    .close {
      width: 12px;
      height: 12px;
      // background-image: url('../assets/close.png');
    }
  }
  .s-logo {
    width: 50px;
    margin-left: 100px;
    -webkit-app-region: no-drag;
  }
  .word {
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    -webkit-app-region: no-drag;
  }
}
.layout-shell {
  display: flex;
}
.layout {
  min-width: var(--min-layout-width);
}
.layout-navigation {
  width: 240px;
  min-width: 60px;
  min-height: calc(100vh - 48px);
  padding-top: 22px;
  background-color: #252525;
}

.layout-content {
  flex: 1;
  width: calc(100% - 240px);
  height: calc(100vh - 48px);
  padding: 15px;
  overflow: auto;
  background-color: #f7f7f7;
}

@media (max-width: 980px) {
  .layout-navigation {
    width: 180px;
  }
}

.layout-content {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.layout-content::-webkit-scrollbar {
  width: 0;
  height: 5px;
  background-color: #181818;
}

.layout-content::-webkit-scrollbar-track {
  background-color: #181818;
  border-radius: 0;
}

.layout-content::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 0;
  opacity: 0.9;
}
