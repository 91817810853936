.graph-tree {
  position: relative;

  margin-top: 32px;
  overflow: auto;

  .graph-node {
    position: absolute;
    display: flex;
    width: 200px;
    margin: 10px;
    padding-right: 1em;
    padding-left: 3.5em;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s linear;

    .name {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      overflow: hidden;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      .kind {
        margin: 0;
        color: #a6a6a6;
        font-size: 12px;
      }
      .healthy.success {
        color: #28a745;
      }
      .actions {
        justify-content: start;
        margin-left: 0;
      }
    }

    .icon {
      position: absolute;
      left: 0.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;

      img {
        width: 30px;
      }
    }

    .icon-error {
      color: var(--danger-color);
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-left: 16px;
      i {
        color: #666;
      }
    }
    .additional {
      position: absolute;
      right: 8px;
      bottom: -12px;
      display: block;
    }
  }

  .graph-node-app {
    padding-left: 5em;
    .name {
      line-height: 40px;
    }
    .icon {
      top: -10px;
      left: -10px;
      width: 60px;
      height: 60px;
      background: #fff;
      border: 2px solid (var(--primary-color));
      border-radius: 30px;

      img {
        width: 50px;
      }
    }
  }

  .graph-node.node-error {
    box-shadow: 1px 1px 1px (var(--danger-color));
  }

  .graph-node-pod {
    .icon {
      display: flex;
      flex-direction: column;
      height: 60px;
      span {
        color: #a6a6a6;
        font-size: 12px;
      }
    }
    .name {
      line-height: 30px;
      .actions {
        .next-icon {
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .graph-node.error-status {
    border: solid 0.2em var(--danger-color);
  }
  .graph-node.warning-status {
    border: solid 0.2em var(--warning-color);
  }

  .graph-edge {
    .graph-edge-line {
      position: absolute;
      z-index: -1;
      border-top: 1px dashed #666;
      transition: all 0.2s linear;

      &:last-child {
        &::after {
          position: absolute;
          top: -10px;
          color: #a3a3a3;
          font-size: 10px;
          transform: rotate(180deg);
          content: '\25BA';
        }
      }
    }
  }
  .line {
    margin: 0;
    padding: 0;
    line-height: 16px;
  }
}
