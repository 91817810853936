.auth-list-wrapper {
  min-height: 500px;
  color: #333;
  font-size: 14px;
  .project-roles-add-title {
    display: block;
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
  }
  .permPolicies-wrapper {
    min-height: 180px;
  }
  .next-checkbox-group {
    margin-left: -2px !important;
  }
  .next-checkbox-wrapper {
    margin-left: 8px;
  }
  .font-weight-400 {
    font-weight: 400;
  }
  .create-auth-btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -40px;
  }
}
.project-role-empty-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}
