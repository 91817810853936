.app-menu {
  height: 100%;
  .next-card-body {
    padding: 0;
    padding-top: 0 !important;
    a {
      padding: 16px;
      color: #000;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
