.summary-targets-wrapper {
  margin-top: 40px;
  background: #fff;
  .card-title-wrapper {
    display: flex;
    justify-content: space-between;
    .card-title {
      padding-top: 20px;
      padding-left: 20px;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .card-button-wrapper {
    margin-top: 20px;
    margin-right: 20px;
  }
  .card-content-table {
    height: 150px;
    min-height: 100px;
    margin: 16px 30px 0 30px;
    overflow: scroll;
  }
  .color-setting {
    span {
      color: #333;
    }
  }
  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
    .next-btn-text {
      padding-left: 0;
    }
  }
}
