.full {
  display: flex;
  align-items: center;
  justify-items: center;
  height: calc(100vh - 48px);
  background: #f7f7f7;
  .login-wrapper {
    width: 400px;
    margin: 0 auto;
    .logo-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      img {
        width: 280px;
      }
    }
    .login-card-wrapper {
      .label-title {
        color: #202529;
        font-size: 16px;
        cursor: pointer;
      }
      .login-title-description {
        padding-bottom: 20px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
      }
      .next-card-show-divider {
        width: 400px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        .next-card-content {
          height: auto;
          .next-btn {
            display: block;
            margin: 0 auto;
            color: #fff;
            font-size: 16px;
            text-align: center;
          }
          .next-btn:hover {
            box-shadow: none;
          }
        }
        .next-form {
          height: 210px !important;
        }
        .next-card-footer {
          display: none;
        }
      }
      .logo-error-wrapper {
        margin-bottom: 10px;
        color: red;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}

.login-topbar {
  width: 100%;
  padding: 0 16px;
  background-color: var(--btn-pure-primary-bg-color);
  box-shadow: 5px 0 8px #888;
  -webkit-app-region: drag;
  .nav-wrapper {
    position: relative;
    width: 100%;
    height: 48px;
    .logo {
      display: flex;
      align-items: center;
      margin-left: 9px;
      border-radius: 3px;
      img {
        height: 40px;
      }
    }
  }
  .right {
    display: flex;
    float: right;
    height: 48px;
    margin-left: auto;
    overflow: hidden;
    .vela-item {
      display: flex;
      align-items: center;
      justify-items: center;
      height: 48px;
      padding: 0 8px;
      color: #fff;
      cursor: pointer;
      transition: all 0.3s;
      z-index: 2000;
      a {
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
