.user-wrapper {
  .user-list-wrapper {
    .next-table .next-table-header th .next-table-cell-wrapper {
      display: flex;
      justify-content: flex-start;
    }
    .next-table-header-icon {
      margin-top: 2px;
    }
    .next-table-cell.last .next-table-cell-wrapper {
      text-align: left;
    }
    .roles-permPolicies {
      display: inline-block;
      padding: 6px 16px;
      color: #fff;
      background: #47a664;
      border-radius: 14px;
    }
  }
}
