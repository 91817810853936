.card-content-wrapper {
  padding: 16px;
  padding-bottom: 0;
  border: none;
  .next-card {
    border: none;
    border-radius: 8px;
  }
  .next-card-show-divider .next-card-body {
    padding: 0;
  }
  .content-wrapper {
    padding: 20px 10px 0 10px;
    background: #f7f6fb;
    .content-title {
      font-weight: 500;
      font-size: 18px;
    }
    .content-main {
      display: -webkit-box;
      height: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .content-foot {
      padding: 16px 0 24px;
    }
  }
}

.cluster-card-top {
  height: 120px;
  background: #ebf0ff;
  img {
    height: 60px;
  }
}
.dot-wrapper {
  width: 100%;
  .action {
    float: right;
    height: 24px;
    color: #a6a6a6;
    cursor: pointer;
  }
}
