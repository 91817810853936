.addon-search {
  padding: 16px;
  background: #fff;
  .tag-search {
    display: flex;
    align-items: stretch;
    padding: 16px 8px;
    .tag-name {
      display: flex;
      flex-grow: 0;
      align-items: center;
      width: 40px;
      height: auto;
      font-weight: 600;
    }
    .tag-list {
      min-width: 300px;
      flex-grow: 1;
      .next-checkbox-wrapper {
        margin: 4px 16px 4px 0px;
      }
    }
  }
  .ext-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
