.user-select-wrapper {
  background: #fff;
  .item {
    min-width: 90%;
    margin: 16px 0;
  }
  .btn-wrapper {
    float: right;
    margin: 20px;
  }
}

.margin-right-20 {
  margin-right: 20px;
}
