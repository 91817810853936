.basic-config-wrapper {
  margin-top: -36px;
  .title {
    h3 {
      margin-top: 0;
    }
  }
  .properties-config-title {
    display: flex;
    justify-content: space-between;
    margin: 10px 12px;
  }
  .ui-schema-container {
    width: 100%;
  }
  .edit-component-update-btn {
    display: flex;
    justify-content: center;
  }
}

.trait-config-wrapper {
  margin-top: 10px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  .title {
    h3 {
      margin-top: 0;
    }
  }
}

.save-btn {
  color: #276bf8;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.withActions {
  .next-card-title {
    width: 100%;
    max-width: 100%;
    .next-card-subtitle {
      float: right;
    }
  }
}

.footer-actions {
  display: flex;
}
