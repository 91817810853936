.application-logs-wrapper {
  padding-bottom: 0 !important;
  .logDate {
    text-decoration: underline dotted;
    cursor: pointer;
  }
}
.application-logs-actions {
  float: right;
  margin-top: -35px;
  .next-switch {
    margin-top: 8px;
  }
  .download {
    margin-right: var(--spacing-4);
  }
}

.logBox {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(~'100vh - 410px');
  padding: 16px;
  overflow: scroll;
  color: #fff;
  background: #000;
}
