.podlist-table-wrapper {
  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
  }
}

.container-table-wrapper {
  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
  }
}

.event-table-wrapper {
  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
  }
}

.deployNotice {
  display: flex;
  justify-content: center;
  .noticeBox {
    width: 300px;
    margin-top: 60px;
    padding: 16px;
    background: #fff;
    h2 {
      font-size: 18px;
      text-align: center;
    }
    .desc {
      color: #a6a6a6;
      text-align: center;
    }
    .noticeAction {
      margin-top: 16px;
      text-align: center;
    }
  }
}

.code {
  display: block;
  width: 100%;
  margin: 8px 0;
  padding: 8px;
  color: #fff;
  background: #000;
}

.operations {
  display: flex;
  .actionIcon {
    display: flex;
    align-items: center;
  }
}
