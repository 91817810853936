.policy-list-content {
  padding-top: 11px;
}
.curlCode {
  code {
    display: block;
    padding: 8px;
    color: #fff;
    background: #000;
  }
}

.policy-list-nav {
  display: flex;
  justify-content: space-between;
  .policy-list-title {
    font-size: 16px;
  }
}
