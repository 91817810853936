.empty {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px 16px;
  svg {
    width: 80px;
  }
  .message {
    padding: 8px;
    color: var(--grey-500);
    font-size: 16px;
  }
}
