.title-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  line-height: 36px;
  .title {
    font-weight: 500;
    font-size: 18px;
  }
  .subTitle {
    margin-left: 15px;
  }
}

@media (max-width: 719px) and (min-width: 480px) {
  .title-wrapper {
    .subTitle {
      display: none;
    }
  }
}
