.cloudshell-layout {
  .next-drawer-header {
    .next-drawer-close {
      .next-drawer-close-icon.next-icon::before {
        color: #fff;
      }
    }
  }
  .next-drawer-body {
    height: 100%;
    padding: 0;
    overflow: hidden;
    background: rgb(59, 59, 59);
    .prepare {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      text-align: center;
    }
    .full-screen {
      position: absolute;
      top: 16px;
      right: 44px;
      cursor: pointer;
    }
  }
}
