.trigger-list-content {
  min-height: 109px;
  padding: 11px 0;
}
.curlCode {
  code {
    display: block;
    padding: 8px;
    color: #fff;
    background: #000;
  }
}

.trigger-list-nav {
  display: flex;
  justify-content: space-between;
  .trigger-list-title {
    color: var(--primary-color);
    font-size: 16px;
    cursor: pointer;
  }
}

.justify-tabs-tab.active{
  background-color: #000 !important;
  color: #fff !important;
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-tab{
  border: none !important;
}