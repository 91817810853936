.string-container {
  display: flex !important;
  align-items: center;
  margin-bottom: 8px;
  background-color: #e2e2e2;
  border: 1px solid #eee;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  .next-input {
    flex: 1 1 auto;
  }
  .remove-option-container {
    flex: 0 0 36px;
    text-align: center;
  }
  .next-icon-ashbin {
    cursor: pointer;
    &::before {
      color: #fff;
      font-size: 18px !important;
    }
  }
}
.add-btn-container {
  text-align: right;
}
