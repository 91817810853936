.replica {
  .next-card-content {
    display: flex;
    .replica-num {
      flex: 1;
      margin-left: 32px;
      h5 {
        margin-top: 16px;
        font-size: 16px;
      }
    }
  }
}

.list-warper {
  height: calc(100% - 80px);
  min-height: 300px;
  padding: 0 8px;
  .box {
    height: 100%;
    background: #fff;
    .box-item {
      margin-bottom: 4px;
      .next-card {
        min-height: 80px;
      }
    }
  }
}
.app-spec {
  display: flex;
  .app-spec {
    align-self: stretch;
  }
}

.app-name {
  margin: 0;
  font-size: var(--font-size-x-large);
  line-height: 50px;
}

.flex-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
