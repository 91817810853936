.project-detail-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  margin-top: 16px;
  background: #fff;
  ul {
    display: flex;
    a {
      display: block;
      width: 150px;
      color: #333;
      font-weight: 400;
      font-size: 14px;
      line-height: 60px;
      text-align: center;
    }
    .active {
      color: var(--primary-color);
      border-bottom: 2px solid var(--primary-color);
      a {
        color: var(--primary-color);
      }
    }
  }
}
