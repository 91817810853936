.app-layout {
  .main {
    display: flex;
    margin-bottom: 32px;
    .menu {
      align-self: stretch;
      width: 200px;
      min-width: 200px;
      margin-right: 16px;
    }
    .content {
      flex: 1;
      align-self: stretch;
      max-width: calc(100% - 200px);
      min-height: 600px;
    }
  }
}
