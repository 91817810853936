.dependency-item {
  font-size: 14px;
}

.custom-cluster-checkbox {
  .next-checkbox-wrapper {
    width: 25%;
  }
}

.addon-readme {
  font-size: 14px !important;
  img {
    width: 100%;
  }
  ol,
  ul {
    list-style: decimal;
  }
  p {
    font-size: 14px;
  }
}
.addon-readme li > p {
  font-size: 14px;
}

.warning-text {
  color: var(--warning-color);
}
