.uiSchema-wrapper {
  .view-ui-schema {
    height: calc(100vh - 357px);
    overflow-y: auto;
  }
  .preview {
    padding: 8px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    background: #0064c8;
    border-radius: 8px 8px 0 0;
    opacity: 0.8;
  }
}
