.definitions-list-content {
  min-height: calc(100vh - 60px);
  margin-left: 16px;
  padding: 16px;
  padding-top: 0;
  border: 1px solid #ccc;
  .next-table .next-table-header th .next-table-cell-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
    .next-btn-text {
      padding-left: 0;
    }
  }
  .next-table-header-icon {
    margin-top: 2px;
  }
  .enableStatus {
    color: #20ad7e;
  }
  .disableStatus {
    color: #f00;
  }
}
