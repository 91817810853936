.customDrawer.next-drawer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .next-drawer-body {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 24px;
    overflow: auto;
    .drawer-footer {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(~'100% - 40px');
      height: 60px;
      background: #fff;
    }
  }
}
