.project-roles-wrapper {
  width: 1200px;
  min-height: 400px;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  .project-auth-border-left {
    position: relative;
    border-left: 1px solid #d8d8d8;
  }
  .next-checkbox-inner {
    .next-checkbox-select-icon {
      margin-top: -8px !important;
    }
  }
}
