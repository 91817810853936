.config-wrapper {
  margin-top: 40px;
  background: #fff;
  .card-title-wrapper {
    display: flex;
    justify-content: space-between;
    .card-title {
      padding-top: 20px;
      padding-left: 20px;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .card-button-wrapper {
    margin-top: 16px;
    margin-right: 16px;
  }
  .card-content-table {
    min-height: 100px;
    padding: 16px;
    overflow: scroll;
  }
  .color-setting {
    span {
      color: #333;
    }
  }

  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
    .next-btn-text {
      padding-left: 0;
    }
  }
  .isReadyColor {
    color: #20ad7e;
  }
  .isNotReady {
    color: #f00;
  }
}
