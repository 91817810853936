.project-menu-wrapper {
  .menu-role-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    color: #333;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
  }
  .add-roles-btn {
    display: block;
    margin-left: 20px;
    padding: 14px 0;
    color: #1b58f4;
    font-size: 14px;
    cursor: pointer;
  }
  .active {
    color: #fff;
    background-image: linear-gradient(to right, #0830f1, #0a448b);
  }
}
