@primaryColor: #0064c8;
@whiteColor: white;
@blackColor: black;
.spection-group-container {
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  visibility: visible;
  &:hover {
    border: 1px solid @primaryColor;
  }
  .spection-group-title-container {
    position: relative;
    padding: 0 10px;
    font-size: 14px;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: border-color 0.12s;
    .icon-toggle {
      position: absolute;
      top: -2px;
      right: 10px;
    }
    .icon-delete {
      position: absolute;
      right: 30px;
    }
    .next-icon-arrow-up {
      &::before {
        font-size: 12px !important;
      }
    }
  }
  .array-item-group-box {
    padding: 16px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    transition: height 0.3s linear;
  }
  .array-item-group-box.disable {
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    visibility: hidden;
  }
}
