.list-content {
  min-height: calc(100vh - 60px);
  margin-left: 16px;
  padding: 16px;
  padding-top: 0;
  border: 1px solid #ccc;
  .production-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: #1b58f4;
    font-size: 16px;
  }
  .create-btn {
    margin: 16px 0;
    text-align: right;
  }
  .next-table .next-table-header th .next-table-cell-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .next-table-cell.last .next-table-cell-wrapper {
    text-align: left;
    .next-btn-text {
      padding-left: 0;
    }
  }
  .next-table-header-icon {
    margin-top: 2px;
  }
  .isReadyColor {
    color: #20ad7e;
  }
  .isNotReady {
    color: #f00;
  }
}
