.application-status-wrapper {
  .tags-content {
    .next-tag-small {
      margin-bottom: 0;
    }
    .next-tag-body {
      cursor: default !important;
    }
    .next-tag:not([disabled])[class*='next-tag-level-'].next-tag.next-tag:not(.next-tag-closable):focus {
      color: #333;
      background-color: var(--tag-bordered-bg, #fff);
    }
  }
  .next-switch {
    width: 80px;
  }
  .next-switch-on,
  .next-switch-off {
    background-color: (var(--primary-color)) !important;
  }
  .next-switch-children {
    display: block !important;
    color: #fff !important;
    text-align: center;
  }
}
